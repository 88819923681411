<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue'

import NavBar from '@/components/NavBar/index.vue'
import SideMenuMobile from '@/components/SideMenuMobile/index.vue'

const { tm, rt } = useI18n()

const isSideMenuOpen = ref(false)

const items = computed(() => (tm('items') as Array<any>).map((item: any) => ({
  id: rt(item.id),
  label: rt(item.label),
  path: rt(item.path),
  class: rt(item.class),
  target: item.target
})))

const openSideMenu = () => {
  isSideMenuOpen.value = true
}

const closeSideMenu = () => {
  isSideMenuOpen.value = false
}

const scrollTop = ref(0)
const showHeader = ref(true)

const handleScroll = () => {
  const currentY = window.scrollY

  if (currentY >= scrollTop.value) showHeader.value = false
  else showHeader.value = true

  scrollTop.value = currentY
}

onMounted(() => {
  window.addEventListener('scroll', () => handleScroll())
})

</script>

<script lang="ts">
export default {
  name: 'AppHeader'
}
</script>

<template>
  <header
    class="fixed top-0 left-0 w-full bg-white z-30 transition-all duration-300 h-64 overflow-y-hidden"
    :class="{ '!h-0': !showHeader}">
    <NavBar
      :items
      :is-side-menu-open
      @on-side-menu-click="openSideMenu" />

    <SideMenuMobile
      v-if="isSideMenuOpen"
      :items
      @on-side-menu-click="closeSideMenu" />
  </header>
</template>

<i18n src="./i18n.json" lang="json"></i18n>
